import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { SimpleQuoteListInteractWithChart, WatchListWithChart } from '~/modules/symbolQuote/simple';
import FuiButton from '../heineken_template/components/FuiButton';
import { store } from '../heineken_template/_private/store';
import { styleds } from './styleds';
import { yaya168Stock_store } from './yaya168Stock_store';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { ScreenerContent } from '~/pages/yaya168_stock/ScreenerContent/ScreenerContent';
import { usePopularPick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
export const Yaya168Stock_SidePane2 = memo(function Yaya168Stock_SidePane2() {
    const state = useSnapshot(yaya168Stock_store);
    /** 即時訊號 */
    //最近的一天有交易資料的日期
    const intraday = useSnapshot(staticStore).tradedDate.intraday.format('YYYY-MM-DD');
    //每5分拿一次最新交易日 //fix 檢查
    useEffect(() => {
        const intervalId = setInterval(() => {
            intraday;
        }, 5 * 1000 * 60);
        return () => clearInterval(intervalId);
    }, []);
    // const potentialSymbol = useFirebaseValue<SignalSymbolArray>('yaya168_signal', 'long')
    // const resultSymbol2 = potentialSymbol?.symbols
    const popularData = usePopularPick({ date: intraday, sort: 'volume', limit: 25 });
    const popularSymbol = popularData.resultSymbol?.filter(item => item.length < 5);
    return (<styleds.SideBar2>
      <styleds.ButtonContent>
        <yaya168Stock_store.tabsOfMain.WithButton to='轉折股'>
          <FuiButton.Display>轉折股</FuiButton.Display>
        </yaya168Stock_store.tabsOfMain.WithButton>
        <yaya168Stock_store.tabsOfMain.WithButton to='熱門股'>
          <FuiButton.Display>熱門股</FuiButton.Display>
        </yaya168Stock_store.tabsOfMain.WithButton>
      </styleds.ButtonContent>

      {state.tabsOfMain.store.active === '轉折股' && (<styleds.SymbolListContent>
          <ScreenerContent realTimeApiAgent={'yaya168'} virtualAccountCode={'yaya168_stock_long3'}/>
        </styleds.SymbolListContent>)}
      {state.tabsOfMain.store.active === '熱門股' && (<styleds.SymbolListContent>
          <SimpleQuoteListInteractWithChart data={popularSymbol ?? []} chart={store.charting}/>
        </styleds.SymbolListContent>)}

      <styleds.ButtonContent>
        <FuiButton.Display>自選股</FuiButton.Display>
      </styleds.ButtonContent>

      <styleds.SymbolListContent>
        <WatchListWithChart useChart={store.charting} groupName='yaya168_stock_group_1'/>
      </styleds.SymbolListContent>
    </styleds.SideBar2>);
});
